<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('invoicing_title') }}</dmx-title>

        <b-row align-v="center" class="mb-1">
            <b-col>
                {{ pagination.totalListCount }} {{$t('invoicing_invoices_found')}}
                <span class="ml-3">
                    Show: <b-link @click="setPerPage(10)" :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)" :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(1000)" :style="((options.perPage == 1000) ? 'font-weight: bold;' : ' ')">All</b-link>
                </span>
            </b-col>
            <b-col>
                <b-input v-model="searchField"
                         @input="searchWithText()"
                         :loading="searchFieldIsLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
        </b-row>
        <b-table id="devices-table"
                 thead-class="dmx-thead-block"
                 :busy="loading"
                 outlined
                 striped
                 bordered
                 hover
                 :items="invoiceList"
                 :fields="headers"
                 @row-clicked="editInvoice">
            <template #cell(createdOnUtc)="{ value }">
                {{ utcFormatted(value) }}
            </template>
        </b-table>

        <div>
            <b-pagination align="center" v-model="options.currentPage"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.perPage"
                          aria-controls="invoices-table">
            </b-pagination>
        </div>
    </div>
</template>

<style scoped>

    /* gives table headers rounder corners */
    /*/deep/ tr th:first-child {
        border-top-left-radius: 10px;
    }*/

    /* gives table headers rounder corners */
    /*/deep/ tr th:last-child {
        border-top-right-radius: 10px;
    }*/

</style>

<script>
    import invoicingService from '@/services/invoicingService';

    import router from '@/router';

    export default {
        name: "InvoicingList",
        data: () => ({
            search: '',
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1,
            },

            //flags
            searchFieldIsLoading: false,

            // values
            searchField: '',
            invoiceList: [],

            // pagination
            pagination: {
                totalListCount: 0
            },
            filterTableHeadName: '',
            timeoutId: 0,
            bcItems: [
                {
                    text: 'invoices',
                    href: '/invoices',
                    active: true,
                }
            ]
        }),
        computed: {
            headers() {
                return [
                    {
                        label: this.$t('common_id'),
                        align: 'start',
                        sortable: true,
                        key: 'gateId',
                    },
                    {
                        label: this.$t('gateRole_GateOwner'),
                        sortable: true,
                        key: 'ownerCustomerName',
                    },
                    {
                        label: this.$t('common_gate_name'),
                        sortable: true,
                        key: 'gateName',
                    },
                    {
                        label: this.$t('common_device_name'),
                        sortable: true,
                        key: 'deviceName',
                    },
                    {
                        label: this.$t('common_subscription'),
                        sortable: true,
                        key: 'subscription',
                    },
                    {
                        label: this.$t('subscription_expiry_date'),
                        sortable: true,
                        key: 'subscriptionExpiryDateUtc',
                    },
                    {
                        label: this.$t('subscription_warning_date'),
                        sortable: true,
                        key: 'subscriptionWarningDateUtc',
                    },
                    {
                        label: this.$t('common_sim'),
                        sortable: true,
                        key: 'sim',
                    },
                    {
                        label: this.$t('common_sim_status'),
                        sortable: true,
                        key: 'simStatus',
                    },
                    {
                        label: this.$t('gateRole_GateInstaller'),
                        sortable: true,
                        key: 'gateInstallerCustomerName',
                    }
                ]
            }
        },
        watch: {
            options: {
                handler() {
                    this.getInvoiceList()
                },
                deep: true,
                immediate: true,
            },
        },
        methods: {
            setPerPage(num) {
                this.options.currentPage = 1;
                this.options.perPage = num;
            },

            editInvoice(e) {
                router.push({ name: 'editInvoice', params: { deviceId: parseInt(e.id) } })
            },
            searchWithText() {
                this.searchFieldIsLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getInvoiceList();
                }, 600);
            },

            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getInvoiceList();
            },
            getInvoiceList() {
                invoicingService.getInvoiceList("GetAll", {
                    Count: this.options.perPage,
                    Invoices: [],
                    //SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    //SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage
                }).then((data) => {
                    //console.log('--> Invoice List');
                    //console.log(data);
                    this.invoiceList = data;
                    
                    this.searchFieldIsLoading = false;
                    this.options.currentPage = data.page;
                    this.pagination.totalListCount = data.totalListCount;
                    
                    this.loading = false
                })
                .catch(function (error) {
                    alert(error);
                });
            }
        }
    }
</script>
