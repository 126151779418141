"use strict";

import axios from "axios";
const baseControllerAddress = 'invoicing/';

export default {
    getLocale: (id) => {
        return axios.post('/api/' + 'locale/GetById/', id)
            .then(result => {
                return result;
            }).catch(function (error) {
                alert(error);
            });
    },
    getInvoiceList: (action, vm) => {
        return axios.get('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    }
}